<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <auth-illustration></auth-illustration>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <!-- brand logo -->
                  <app-logo></app-logo>
                  <!--/ brand logo -->
                </v-card-text>

                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Froget Password 🔒
                  </p>
                  <p class="mb-2">
                    Enter your email to reset password
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="formRef"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-alert
                      v-if="requestError"
                      color="error"
                      text
                      dark
                      class="my-4"
                    >
                      {{ requestError }}
                    </v-alert>

                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="email"
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-btn
                      block
                      type="submit"
                      color="primary"
                      class="mt-4"
                    >
                      Send reset link
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- back to login -->
                <v-card-actions class="d-flex justify-center align-center mt-2">
                  <router-link
                    :to="{name:'auth-login-v2'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back to login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { required, emailValidator } from '@core/utils/validation'
import axios from '@axios'
import AppLogo from '@/views/components/app/AppLogo.vue'
import AuthIllustration from '@/views/components/auth/AuthIllustration.vue'

export default {
  metaInfo: {
    title: 'Forget Password',
  },
  components: {
    AppLogo,
    AuthIllustration,
  },
  setup() {
    const formRef = ref(null)
    const email = ref('')
    const errorMessages = ref([])
    const requestError = ref('')

    const handleFormSubmit = () => {
      const isFormValid = formRef.value.validate()

      if (!isFormValid) return

      axios
        .post('auth/sendResetLink', {
          email: email.value,
        })
        .then(response => {
          requestError.value = response.data.message || 'No message'
        })
        .catch(err => {
          requestError.value = err.response.data.message || err.response.data.expception
        })
    }

    return {
      email,
      errorMessages,
      requestError,

      validators: {
        required,
        emailValidator,
      },

      handleFormSubmit,
      formRef,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
.v-alert__content {
  white-space: pre-line;
}
</style>
